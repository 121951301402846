@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');


body {
  color: $bodyfont-Color;
  font-family: $bodyfont;
  font-weight: 400;
  line-height: 26px;
  font-size: 14px;
  letter-spacing: 0.2px;
  margin: 0;
  background-color: $theme-DarkColor;
 
  .page {
    overflow: hidden;
    position: relative;
    z-index: 10;
  }
}
.text-right{
  text-align: right;
}
.site-main-cv .container-cv {
  // background-color: aqua;
  margin: auto;
  margin-left: 2%;
  margin-right: 2%;
  // width: 200% !important;


}
.site-main-cv .container-cv .form-row {
  
  // width: 200% !important;


}
.container {
  position: relative;
  max-width: 1200px;

  
  
    @media (max-width: 1200px){
        padding: 0 24px;
       
    }

  
}
/* General */

::selection {
  background: $theme-SkinColor;
  color: $theme-WhiteColor;
  text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

a, a:not([href]) {
  transition: 0.4s;
  color: $theme-WhiteColor;
  outline: none;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: $theme-SkinColor;
    outline: none;
    text-decoration: none;
  }
}



textarea, input {
  &:focus, &:active {
    outline: none;
  }
}

/* Heading Default-Style */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 600;
  font-family: $headingfont;
  color: $headingfont-Color;
  line-height: 1.41;
}

h1, .h1 {
  @include font-size(40px);
  font-weight: 700;
  margin-bottom: 25px;
}

h2, .h2 {
  @include font-size(36px);
  font-weight: 600;
  margin-top: -5px;
  margin-bottom: 18px;
}

h3, .h3 {
  @include font-size(30px);
  margin-bottom: 10px;
}

h4, .h4 {
  @include font-size(26px);
}

h5, .h5 {
  @include font-size(20px);
}
#tooltip> .tooltip-inner {
color: $theme-SkinColor;
background-color: transparent;
font-size: small;

}
#tooltip >.tooltip-arrow {
  margin-top: -6px;
  
  // border-top: 5px solid $theme-GreyColor !important;
  // color: white !important;
}

#tooltip{
  // background-color: $theme-DarkColor;
  background-color: rgba(43 , 52 , 82 , .8)
}


h6, .h6 {
  @include font-size(16px);
}

/* Paragraph Margin */
p {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}
.privacy-large{
 span{
  margin-left: 10px;
 }
 
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}


strong, b {
  font-weight: 700;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  box-shadow: none;
  transition: 0.4s;

  &:active, &:focus {
    outline: none;
    box-shadow: none;
  }
}
.warning span{
  color: $pinterest ;

}
.text-theme-WhiteColor {
	h1:not(.text-theme-SkinColor) {
		color: $theme-WhiteColor;
	}
	h2:not(.text-theme-SkinColor) {
		color: $theme-WhiteColor;
	}
	h3:not(.text-theme-SkinColor) {
		color: $theme-WhiteColor;
	}
	h4:not(.text-theme-SkinColor) {
		color: $theme-WhiteColor;
	}
	h5:not(.text-theme-SkinColor) {
		color: $theme-WhiteColor;
	}
	h6:not(.text-theme-SkinColor) {
		color: $theme-WhiteColor;
	}
  a {
    &:not(.text-theme-SkinColor) {
      color: $theme-WhiteColor;
    }
    &:hover {
      color: $theme-SkinColor;
    }
  }
	span:not(.text-theme-SkinColor) {
		color: $theme-WhiteColor;
	}
}
