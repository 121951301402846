/* /////////// IMAGE ZOOM /////////// */
.banner_slider .slide_img {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}
.slick-active .slide_img {
    -webkit-animation-delay: 24s;
    -moz-animation-delay: 24s;
    -o-animation-delay: 24s;
    -ms-animation-delay: 24s;
    animation-delay: 24s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-animation: imageAnimation 36s linear 0s;
    -moz-animation: imageAnimation 36s linear 0s;
    -o-animation: imageAnimation 36s linear 0s;
    -ms-animation: imageAnimation 36s linear 0s;
    animation: imageAnimation 36s linear 0s;
}
@keyframes imageAnimation { 
  0% { animation-timing-function: ease-in; }
  8% { transform: scale(1.02); animation-timing-function: ease-out; }
  17% { transform: scale(1.04); animation-timing-function: ease-out; }
  22% { transform: scale(1.02); } 25% { -transform: scale(1.1); }
}

.banner_slider.slick-slider {
	.slick-prev {
		opacity: 0;
		visibility: hidden;
		width: 40px;
		height: 40px;
		line-height: 40px;
		padding-right: 3px;
		text-align: center;
		position: absolute;
		left: 0;
		right: 0;
		display: block;
		z-index: 1;
		margin: 0 0;
		background: rgba(34, 54, 69, 0.5);
		border-radius: 50%;
		&:hover {
			background: #000;
		}
		&:before {
			font-family: 'Font Awesome 5 Free';
			font-size: 14px;
			display: inline-block;
			vertical-align: middle;
			line-height: 1;
			opacity: 1;
			color: #fff;
			font-weight: bold;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\f053";
		}
	}
	.slick-next {
		opacity: 0;
		visibility: hidden;
		width: 40px;
		height: 40px;
		line-height: 40px;
		padding-right: 3px;
		text-align: center;
		position: absolute;
		left: 0;
		right: 0;
		display: block;
		z-index: 1;
		margin: 0 0;
		background: rgba(34, 54, 69, 0.5);
		border-radius: 50%;
		right: 0;
		left: auto;
		padding-right: 0;
		padding-left: 3px;
		&:hover {
			background: #000;
		}
		&:before {
			font-family: 'Font Awesome 5 Free';
			font-size: 14px;
			display: inline-block;
			vertical-align: middle;
			line-height: 1;
			opacity: 1;
			color: #fff;
			font-weight: bold;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\f054";
		}
	}
	&:hover {
		.slick-prev {
			opacity: 1;
			margin: 0 20px;
			visibility: visible;
		}
		.slick-next {
			opacity: 1;
			margin: 0 20px;
			visibility: visible;
		}
	}
}


.slide {
	height: 600px;
	align-items: center;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	position: relative;
	z-index: 1;
	.slide__img {
		width: 100%;
		height: auto;
		overflow: hidden;
		position: absolute;
		left: 0;
		img {
			opacity: 1;
			-webkit-animation-duration: 3s;
			animation-duration: 3s;
			transition: all 1s ease;
		}
	}
	.slide__content {
		position: relative;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.slide__content--headings {
		padding: 15px 0;
		position: relative;
		transition: all .4s;
		h2 {
			@include font-size(46px);
			line-height: 1.2;
			margin-bottom: 0;
			color: $theme-WhiteColor;
		}
		>h3 {
			line-height: 1;
			margin-bottom: 20px;
			font-weight: 400;
			font-size: 16px;
			font-family: $bodyfont;
			color: $theme-WhiteColor;
		}
		p {
			font-size: 14px;
			line-height: 26px;
			margin-bottom: 0;
			font-weight: 400;
			color: rgba(255,255,255,.71);
		}
	}
}

.banner_slider_1 {
	.slide {
		.slide__content {
			text-align: center;
			background-image: linear-gradient(180deg,hsla(0,0%,100%,0) -50%,rgba(0, 0, 0, .60));
		}
		.slide__content--headings {
			h2 {
				@include font-size(41.6px);
				line-height: 1.2;
				margin-bottom: 0;
				color: $theme-WhiteColor;
			}
			>h3 {
				line-height: 1;
				margin-bottom: 20px;
				font-weight: normal;
				font-size: 17px;
				font-family: $bodyfont;
				color: $theme-WhiteColor;
			}
			p {
				font-size: 14px;
				line-height: 20px;
				margin-bottom: 0;
				font-weight: 400;
				color: rgba(255,255,255,.71);
			}
			form {
				label {

					@media (max-width: 991px) {
						margin-bottom: 5px;
					}
					input, textarea, select {
						width: 100%;
						height: 100%;
						padding: 16px 15px 17px;
						padding-left: 45px;
						color: $bodyfont-Color;
						border-radius: 3px;
						line-height: 1.3;
						border: 1px solid rgba(119,119,119,.2);
						background-color: #fff;
					}
					select {
						padding-left: 25px;
						background-position: calc(100% - 22px) calc(1em + 10px), calc(100% - 17px) calc(1em + 10px), calc(100% - 3.5em) 0.5em;
					}
				}

			}
		}
	}
}



.slick-slide.slick-active.slick-current {
	.animated {
		-webkit-animation-duration: 1s;
		animation-duration: 1s;
		-webkit-animation-duration: var(--animate-duration);
		animation-duration: var(--animate-duration);
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		transition-delay: 1s;
	}
}
.slick-slide:not(.slick-active.slick-current) {
	.animated {
		opacity: 0;
		-webkit-animation-duration: unset;
		animation-duration: unset;
		-webkit-animation-duration: unset;
		animation-duration: unset;
		transition-delay: unset;
	}
}

.banner_slider_2 {
	.slide {

		.slide__content {
			text-align: center;
			background-image: linear-gradient(180deg,hsla(0,0%,100%,0) -50%,rgba(0, 0, 0, .55));
		}

		.slide__content--headings {
			h2 {
				@include font-size(54px);
				line-height: 1.3;
				margin-bottom: 0;
				font-weight: 700;
				color: $theme-WhiteColor;

				span.bg-search {
					position: absolute;
					content: "";
					height: 100%;
					width: 100%;
					background-image: url();
				}
			}
			
		}
	}
}


.banner_slider_3 {
	.slide {
		height: 800px;
		&.bg-layer {
			.slide__content {
				// background-color: rgba(0,0,0,0.5);
				background-color: $theme-GreyColor;
				border-top: 1px solid $theme-DarkColor;

			
				@media (max-width: 575px) {
					text-align: center;
				}
			}
		}
		.slide__content--headings {
			h2 {
				@include font-size(50px);
				line-height: 1.35;
				margin-bottom: 0;
				font-weight: 600;
				color: $theme-WhiteColor;
				text-transform: capitalize;
			}
			p {
				font-size: 14px;
				line-height: 26px;
				margin-bottom: 0;
				font-weight: 400;
				color: rgba(255, 255, 255, 0.91);
			}

		}
		.cmp-logo {
			display: inline-block;
			vertical-align: middle;
			height: 60px;
			width: 60px;
			border-radius: 50%;
			text-align: center;
			background-color: #fff;
			position: absolute;
    		overflow: hidden;
			box-shadow: 0 0 20px 0 rgba(0,0,0,.21);
			
			&.one {
				top: -90px;
				left: -160px;
				right: 0;
				text-align: center;
				margin: 0 auto;
			}
			&.two {
				top: 20px;
				right: 40px;
			}
			&.three {
				bottom: 7px;
				right: 94px;
			}
			&.four {
				bottom: -80px;
				left: -230px;
				right: 0;
				text-align: center;
				margin: 0 auto;
			}
			&.five {
				top: 40px;
				left: 45px;
			}
		}

		.arrow-one {
			position: absolute;
			top: -15px;
			left: 15px;
		}
		.arrow-two {
			position: absolute;
			bottom: -75px;
			right: 0;
			z-index: 2;
		}

	}
}


/* /////////// RESPONSIVE /////////// */
@media only screen and (max-width: 1199px){

	.banner_slider .slide {
		height: 460px;
	
		.slide__content--headings > h3 {
			margin-bottom: 10px;
			font-size: 18px;
		}
		.slide__content--headings > h2 {
			@include font-size(50px);
			line-height: 1.3;
			margin-bottom: 0;
		}
		.slide__content--headings p {
			font-size: 15px;
			line-height: 25px;
		}
	}

	.banner_slider_1 {
		.slide {
			height: 580px !important;
		}
	}
}

@media only screen and (max-width: 767px){

    .banner_slider .slide {
        height: 360px;

		.slide__content--headings > h3 {
			margin-bottom: 15px;
			font-size: 16px;
		}

		.ttm-btn {
			font-size: 12px;
			padding: 10px 22px 12px 22px;
		}

    }

	.banner_slider_1 {
		.slide {
			height: 500px !important;
		}
	}
}

@media only screen and (max-width: 575px){
    .banner_slider .slide {
        height: 320px;
		
    }
}