/* job */
div.list-filter {
    // background-color: aqua;
    border: 1px solid rgba(255, 141, 0, 0.2);
    background-color: $theme-DarkColor;
    padding: 26px 20px;
    line-height: 1.3;
    border-radius: 6px;
    width: 100%;
    
    

    label.radio {
        color: $placeHolder_color_light;
        border-radius: 50px;
        display: block;
        cursor: pointer;
        margin: 0;
        font-size: 14px;
        text-transform: capitalize;
        -webkit-tap-highlight-color: transparent;

        &:last-child {
            padding-bottom: 0;
        }

        input {
            width: 11px;
            height: 11px;
            border-radius: 50%;
            background: none;
            border: 0;
            box-shadow: inset 0 0 0 1px #9f9f9f;
            box-shadow: inset 0 0 0 1.5px #9f9f9f;
            appearance: none;
            padding: 0;
            margin: 0;
            margin-left: 5px;
            margin-right: 10px;
            transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
            pointer-events: none;

            &:checked {
                box-shadow: inset 0 0 0 3px $theme-SkinColor;
            }
        }

    }

}

.sidebar.job-sidebar {
    // background-color: #ff8d00;
    margin-right: 0 !important;
    color: $bodyfont-Color-light;
    // width: 120%;
    ::placeholder{
        color: $placeHolder_color_light;
    }
    h5 {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 15px;
        text-transform: capitalize;
    }

    .overview-box {
        border: 2px solid $border-Color;
        border-radius: 6px;
        margin-top: 30px;

        .title {
            padding: 15px 30px;
            border-bottom: 1px solid #ebebeb;
            background-color: $theme-GreyColor;

            h5 {
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 0;
                text-transform: capitalize;
            }
        }

        .desc {
            padding: 27px 30px;

            i {
                margin-left: 10px;
                color: $theme-SkinColor;
            }

            .candidate-timeline {
                .timeline-panel {
                    display: table-row;

                    @media (max-width: 575px) {
                        display: block;
                    }

                    .timeline-head {

                        display: table-cell;
                        width: 34%;
                        position: relative;

                        @media (max-width: 575px) {
                            width: 100%;
                            display: block;
                        }

                        h3 {
                            font-size: 16px;
                            line-height: 28px;
                            margin-bottom: 0px;
                            font-weight: 400;
                        }
                    }

                    .timeline-body {
                        display: table-cell;
                        padding-bottom: 25px;
                        padding-left: 60px;
                        position: relative;

                        &:last-child {
                            padding-bottom: 0;
                        }

                        &::before {
                            position: absolute;
                            content: "";
                            top: 8px;
                            left: 16px;
                            height: 100%;
                            width: 1px;
                            background-color: $theme-SkinColor;

                            @media (max-width: 575px) {
                                left: 0;
                                height: 80%;
                            }
                        }

                        @media (max-width: 575px) {
                            padding-left: 20px;
                        }

                        h5 {
                            font-size: 15px;
                            line-height: 30px;
                            font-weight: 400;
                            position: relative;
                            margin-bottom: 5px;

                            &::before {
                                position: absolute;
                                content: "";
                                top: 8px;
                                left: -50px;
                                height: 13px;
                                width: 13px;
                                border-radius: 50%;
                                border: 3px solid $theme-SkinColor;
                                background-color: #fff;

                                @media (max-width: 575px) {
                                    left: -26px;
                                }
                            }
                        }
                    }

                    &:last-child {
                        @media (min-width: 576px) {
                            .timeline-body {
                                &::before {
                                    content: unset;
                                }
                            }
                        }
                    }
                }

            }
        }

        .social-icons {
            li {
                a {
                    height: 24px;
                    width: 24px;
                    line-height: 24px;
                    border: 0;
                    border-radius: 5px;
                    background-color: $theme-DarkColor;
                    font-size: 11px;
                    box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);

                    &:hover {
                        background-color: $theme-SkinColor;
                    }
                }
            }
        }
    }

    .list-filter {

        label.radio {
            padding-bottom: 15px;
            border-radius: 50px;
            display: block;
            cursor: pointer;
            margin: 0;
            font-size: 14px;
            line-height: 1;
            text-transform: capitalize;
            -webkit-tap-highlight-color: transparent;

            &:last-child {
                padding-bottom: 0;
            }

            input {
                margin-left: 10px !important;
                width: 11px;
                height: 11px;
                border-radius: 50%;
                background: none;
                border: 0;
                box-shadow: inset 0 0 0 1px #9f9f9f;
                box-shadow: inset 0 0 0 1.5px #9f9f9f;
                appearance: none;
                padding: 0;
                margin: 0;
                margin-right: 10px;
                transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
                pointer-events: none;

                &:checked {
                    box-shadow: inset 0 0 0 3px $theme-SkinColor;
                }
            }

        }

    }

    .widget {
        border: 1px solid $theme-DarkColor;
        border-radius: 6px;



    }


    .widget-title span p {
        // background-color: #ff8d00;
        color: #919191;
        font-weight: 100;
    }
    .job_list-widget-white.job_list-widget {
        background-color: $theme-DarkColor;
        .widget {
            padding: 0 30px 30px;
            border: 1px solid $theme-DarkColor;
            // border-radius: 6px;
            background-color:$theme-WhiteColor;
            color: $theme-DarkColor;

            .widget-title {
                padding: 16px 30px;
                font-size: 15px;
                line-height: 22px;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                color: $theme-DarkColor;

                &:after {
                    height: 100%;
                    width: 2px;
                    left: 0;
                }

                i {
                    margin-left: 12px;
                    font-size: 26px;
                    line-height: 0;
                    color: $theme-SkinColor;
                    display: inline-block;
                    vertical-align: middle;
                }

                .widget-icon {
                    color: $theme-SkinColor;
                    margin-left: 10px;
                }
            }


            form {
                label {
                    margin-bottom: 15px;

                    input,
                    textarea,
                    select {
                        width: 100%;
                        border-radius: 3px;
                        padding: 11px 15px;
                        color: $theme-DarkColor;
                        border: 0;
                        background-color: $theme-WhiteColor;

                       
                    }
                    
                }
            }

            .list-filter {

                label.radio {
                    padding-bottom: 15px;
                    border-radius: 50px;
                    display: block;
                    cursor: pointer;
                    margin: 0;
                    font-size: 14px;
                    line-height: 1;
                    text-transform: capitalize;
                    -webkit-tap-highlight-color: transparent;

                    &:last-child {
                        padding-bottom: 0;
                    }

                    input {
                        width: 11px;
                        height: 11px;
                        border-radius: 50%;
                        background: none;
                        border: 0;
                        box-shadow: inset 0 0 0 1px #9f9f9f;
                        box-shadow: inset 0 0 0 1.5px #9f9f9f;
                        appearance: none;
                        padding: 0;
                        margin: 0;
                        margin-right: 10px;
                        transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
                        pointer-events: none;

                        &:checked {
                            box-shadow: inset 0 0 0 3px $theme-SkinColor;
                        }
                    }

                }

            }
        }
       
        
    }

    .job_list-widget {
        padding: 30px;
        border: 1px solid $border-Color;
        border-radius: 6px;
        background-color: $theme-GreyColor;
        overflow: hidden;
        .candidate-widget ul li  {
           
        color: $theme-WhiteColor;

        }
        .candidate-widget ul li span {
            margin-right: 5px;
        color: $theme-WhiteColor;

        }
        .candidate-widget ul li span a{
        color: $theme-WhiteColor;

        }

        &:not(:last-child) {
            margin-bottom: 30px;
        }

        .widget.form-widget ul li span {
            margin-right: 5px;
            text-align: left;
            word-break: break-all;
            
        }

        // Location-Map widged
        .location-widget i {
            margin-left: 5px;
        }

        // .location-widget {
        //     color: $theme-DarkColor;
        // }

        .widget {
            padding: 0 30px 30px;
            border: 0;
            border-radius: 6px;
            background-color:$theme-DarkColor;
            

            .widget-title {
                padding: 16px 30px;
                font-size: 15px;
                line-height: 22px;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                color: $theme-WhiteColor;

                &:after {
                    height: 100%;
                    width: 2px;
                    left: 0;
                }

                i {
                    margin-left: 12px;
                    font-size: 26px;
                    line-height: 0;
                    color: $theme-SkinColor;
                    display: inline-block;
                    vertical-align: middle;
                }

                .widget-icon {
                    color: $theme-SkinColor;
                    margin-left: 10px;
                }
            }


            form {
                label {
                    margin-bottom: 15px;

                    input,
                    textarea,
                    select {
                        width: 100%;
                        border-radius: 3px;
                        padding: 11px 15px;
                        color: $theme-WhiteColor;
                        border: 0;
                        background-color: $theme-GreyColor;
                    }
                }
            }

            .list-filter {

                label.radio {
                    padding-bottom: 15px;
                    border-radius: 50px;
                    display: block;
                    cursor: pointer;
                    margin: 0;
                    font-size: 14px;
                    line-height: 1;
                    text-transform: capitalize;
                    -webkit-tap-highlight-color: transparent;

                    &:last-child {
                        padding-bottom: 0;
                    }

                    input {
                        width: 11px;
                        height: 11px;
                        border-radius: 50%;
                        background: none;
                        border: 0;
                        box-shadow: inset 0 0 0 1px #9f9f9f;
                        box-shadow: inset 0 0 0 1.5px #9f9f9f;
                        appearance: none;
                        padding: 0;
                        margin: 0;
                        margin-right: 10px;
                        transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
                        pointer-events: none;

                        &:checked {
                            box-shadow: inset 0 0 0 3px $theme-SkinColor;
                        }
                    }

                }

            }
        }
    }

    .widget.widget-download {
        li {
            padding: 22px 30px !important;
            font-size: 16px;
            line-height: 1;
            font-weight: bold;
            margin-bottom: 20px;
            font-family: $headingfont;
            background-color: $theme-WhiteColor;
            position: relative;
            border: 0;
            border-radius: 6px;

            a {
                font-weight: bold;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:before {
                position: absolute;
                content: "";
                bottom: 0px;
                opacity: 1;
                left: 0;
                width: 2px;
                height: 100%;
                transition: all .4s;
                background-color: $theme-SkinColor;
            }

            i {
                height: 59px;
                width: 69px;
                line-height: 59px;
                text-align: center;
                position: absolute;
                right: 0;
                top: 0;
                color: $theme-SkinColor;
                border-left: 10px solid $theme-GreyColor;
            }
        }
    }

    .showing-result-block {
        padding: 15px 30px 20px;
        background-color: $theme-GreyColor;
        border: 1px solid $border-Color;
        border-radius: 6px;
        font-size: 15px;
        font-family: $headingfont;
        font-weight: bold;
        color: $theme-WhiteColor;
        margin-bottom: 32px;

        select {
            border: 0;
            outline: 0;
            box-shadow: unset;
            font-family: $bodyfont;
            color: $bodyfont-Color;
            background-color: transparent;
            margin-left: 5px;
        }
    }

    .job-pagination-block {
        padding: 8px 20px;
        border: 1px solid $border-Color;
        border-radius: 6px;
        text-align: center;
        display: table;
        margin: 0 auto;
        margin-top: 35px;
        background-color: $theme-GreyColor;

        .page-nav-link.no {

            color: inherit;
            text-decoration: none;
        }

        .page-nav-link {

            display: inline-block;
            text-align: center;
            padding: 10px 14px;
            margin: 4px;
            border-radius: 6px;
            font-size: 16px;
            line-height: 1;
            text-transform: capitalize;
            background-color: #FFFFFF;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05);
            color: $theme-DarkColor;

            &:hover,
            &.current {
                background-color: $theme-SkinColor;
                color: $theme-WhiteColor;
            }

            &:active {

                background-color: $theme-SkinColor;
                color: $theme-WhiteColor;
            }
        }
    }
}

/* candidate */

.candidate-data {
    padding: 30px;
    border: 1px solid $theme-DarkColor;
    border-radius: 6px;
    display: flex;
    // width: 120%;
    background-color: $theme-GreyColor;
    align-items: center;

    .candidate-img {
        height: 102px;
        width: 102px;
        border-radius: 6px;
        margin-right: 30px;
        overflow: hidden;
    }

    .candidate-caption {
        margin-right: 20px;

        h5 {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 5px;
        }

        >span {
            font-size: 14px;
            color: $theme-SkinColor;
        }

        .meta-line {
            width: 100%;
            margin-top: 10px;


            @media (max-width: 575px) {
                padding-bottom: 15px;
            }

            span {
                font-size: 14px;
                line-height: 26px;
                margin-bottom: 5px;
                margin-left: 20px;
                display: inline-block;

                i {
                    font-size: 13px;
                    margin-left: 5px;
                    color: $theme-SkinColor;
                }
            }
        }
    }
}