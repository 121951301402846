.Modal {
    position: fixed;
    top: 20%;
    left: 20%;
    right: 20%;
    bottom: 40px;
    /* background-color: transparent; */
  }

  .Overlay {
    position: relative;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  
  }