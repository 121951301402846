//== Typography Variable
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;500&display=swap');
$bodyfont: 'Cairo', sans-serif;
$bodyfont-Size: 14px;
$baseFontSize: 15px;
$headingfont: 'Cairo', sans-serif;

//== Theme Typo Colors
$bodyfont-Color: #919191;
$bodyfont-Color-light: #cacaca;
$bodyfont-Color-dark: #181e31;
$placeHolder_color_light: #d3d0d0;
$headingfont-Color: #ffffff;
$border-Color: #202841;


//== Theme Color
// #bc4123
$theme-SkinColor: #FF8C01;
$theme-DarkColor: #2b3452;
$theme-GreyColor: #404966;
$theme-WhiteColor: #FFFFFF;
$darker-color: rgba(0, 0, 0, 0.4);
//== Social Media Icons Color
$facebook: #3B5999;
$google: #d34836;
$twitter: #1DA1F2;
$pinterest: #CB2028;
$youtube: #CC332D;
$reddit: #FF4500;
$linkedin: #0077B5;


// Background Colors
.bg {

    &-theme-SkinColor {
      background-color: $theme-SkinColor;
    }
  
    &-theme-DarkColor {
      background-color: $theme-DarkColor;
    }
  
    &-theme-GreyColor {
      background-color: $theme-GreyColor;
    }

    &-theme-WhiteColor {
      background-color: $theme-WhiteColor;
    }
    &-whitee{
      background-color: #ffffff;
    }
    &-darker-color{
      background-color: $darker-color;
    }

  }
  
  // Text Colors
  .text {

    &-theme-SkinColor {
      color: $theme-SkinColor;
    }
  
    &-theme-DarkColor {
      color: $theme-DarkColor;
    }
  
    &-theme-GreyColor {
      color: $theme-GreyColor;
    }

    &-theme-WhiteColor {
      color: $theme-WhiteColor;
    }
    &-bold{
      font-weight: bold;
    }
    &-placeHolder-color-light{
      color: #d3d0d0;
    }
    &-black{
      color: #000;
    }

  }  
.privacy{
  &-large{
    font-size: large;
    margin: 10px;
  }
}
.div{
  &-border{
    border-right: 1px solid $theme-WhiteColor;
  }
  &-border-bottom{
    border-bottom: 1px solid $theme-WhiteColor;
  }
}